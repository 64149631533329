import React from "react"
import PropTypes from "prop-types"
import Styles from "./index.module.scss"

import mission1 from "../../images/mission1.jpg"
import mission2 from "../../images/mission2.jpg"
import mission3 from "../../images/mission3.jpg"

function Mission({ title, description }) {
  const image = title.startsWith('Be') ? mission1 : title.startsWith('Value') ? mission2 : mission3
  return (
    <div className={Styles.mission} style={{ backgroundImage: `url(${image})` }}
         data-sal="fade" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease-out">
      <div>
        <h3 class="mincho">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  )
}

Mission.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

Mission.defaultProps = {
  title: ``,
  description: ``,
}

export default Mission
