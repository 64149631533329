import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Styles from "./index.module.scss"

function Profile({ title, job, name, description, color }) {
  const images = useStaticQuery(graphql`
    query {
      muramoto: file(relativePath: { eq: "muramoto.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      furusawa: file(relativePath: { eq: "furusawa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)
  const imageKey = (name === '村本 耀一') ? 'muramoto' : (name === '古澤 智裕') ? 'furusawa' : null

  return (
    <div className={Styles.profile} data-sal="fade" data-sal-duration="800" data-sal-easing="ease-out">
      <div class="row">
        {imageKey && <Img style={{ borderRadius: `50%`, width: `100px`, margin: `20px 20px 20px 0` }} fluid={images[imageKey].childImageSharp.fluid} />}
        <div className={Styles.name}>
          <h3 class="gothic">{title}</h3>
          <h5 class="gothic" style={{ background: `linear-gradient(transparent 60%, ` + color + ` 50%)` }}>{job}</h5>
          <h2 class="gothic">{name}</h2>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  )
}

Profile.propTypes = {
  title: PropTypes.string,
  job: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
}

Profile.defaultProps = {
  title: ``,
  job: ``,
  name: ``,
  description: ``,
  color: `#fff`,
}

export default Profile
