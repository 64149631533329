import React from "react"

import Layout from "../layouts/index"
import SEO from "../components/seo"

import Service from "../components/index/service"
import Profile from "../components/index/profile"
import Mission from "../components/index/mission"
import Corporate from "../components/index/corporate"

const IndexPage = () => (
  <Layout>
    <SEO title="株式会社TenGen" />

    <div style={{ backgroundColor: `#fff`, padding: `100px 0 200px` }}>
      <div class="inner">
        <h2 class="caption" data-sal="fade" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease-out">Our Mission</h2>
        <Mission title="Be proud of trying"
                 description="挑戦に誇りを" />
        <Mission title="Value emotions"
                 description="感情に価値を" />
        <Mission title="Make repeatable success"
                 description="再現可能な成功を" />
      </div>
    </div>

    <div style={{ backgroundColor: `#f0f0f0`, padding: `100px 0 200px` }}>
      <div class="inner">
        <h2 class="caption" data-sal="fade" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease-out">Service</h2>
        <Service />
      </div>
    </div>

    <div style={{ backgroundColor: `#fff`, padding: `100px 0 200px` }}>
      <div class="inner">
        <h2 class="caption" data-sal="fade" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease-out">Member</h2>
        <div class="row">
          <Profile title="代表取締役" job="医師" name="村本 耀一" color="#fec1fe"
                  description="<p>高校1年生の時に家族をがんで亡くし、がん治療を行う医師を志す。</p>
                  <p>2017年 筑波大学医学群医学類卒業し、順天堂大学放射線医学教室放射線治療学講座に入局。がんの放射線治療を専攻。</p>
                  <p>医師として勤務する傍ら、病院に来る前の人へアプローチする方法を模索し、医療とITをつなオンラインコミュニティ「MIラボ」を設立。医療やスタートアップに関するイベントを主催し計500名以上に参加いただく。</p>
                  <p>2020年7月に株式会社TenGenを設立し、代表取締役に就任。データサイエンスを医療現場やビジネスに活用する事業を展開する。</p>" />
          <Profile title="代表取締役" job="データサイエンティスト" name="古澤 智裕" color="#c1fefe"
                  description="<p>大学在学中に複数の国内大手IT企業でエンジニアリングやデータサイエンスの実務に関わる。</p>
                  <p>大学院で研究活動の後リクルートホールディングスにデータサイエンティストとして入社し、デジタルマーケティングやシステム構築、新規事業などに従事。</p>
                  <p>現在は国内大手ECベンチャーでデータ分析やシステム開発などを担当し、レコメンド機能や検索機能の改善に従事している。</p>
                  <p>本業と並行して数社のデータ活用アドバイザーとしても活躍する。</p>" />
        </div>
      </div>
    </div>

    <div style={{ textAlign: `center`, margin: `0 0 200px 0` }}>
      <a href="mailto:contact@tengen.tech"
         style={{ textDecoration: `none`, color: `#000`, border: `1px solid #000`, padding: `20px 40px` }}>お問い合わせ</a>
    </div>

    <Corporate />

  </Layout>
)

export default IndexPage
