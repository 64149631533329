import React from "react"
import Styles from "./index.module.scss"

function Service({}) {
  return (
    <div className={Styles.service}
         data-sal="fade" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease-out">
      <h3 class="gothic">【データサイエンス×ビジネスコミュニケーション】<br/>現役データサイエンティストが教える「伝えて動かすデータ分析」</h3>
      <div class="row">
        <iframe title="udemy" src="https://www.youtube.com/embed/G3GPbKp02Y8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <div className={Styles.description}>
          <p>Pythonを用いてビジネス現場で活用できるデータ分析を行うための入門的内容をUdemyにて開講しています。</p>
          <p>ご要望に合わせたカスタマイズの講習や出張講習のご相談も承っております。お気軽にお問い合わせください。</p>
          <a class="gothic" href="https://t.co/DBKFdjgaVG" target="_blank" rel="noreferrer">more</a>
        </div>
      </div>
    </div>
  )
}

export default Service
