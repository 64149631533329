import React from "react"
import Styles from "./index.module.scss"

const Corporate = () => (
  <div className={Styles.corporate}>
    <h3 class="gothic">会社概要</h3>
    <div className={Styles.table}>
    <div>
      <p>会社名</p>
      <p>株式会社TenGen</p>
    </div>
    <div>
      <p>所在地</p>
      <p>〒113-0033 東京都文京区本郷2丁目9番9-803号</p>
    </div>
    <div>
      <p>設立</p>
      <p>2020年7月</p>
    </div>
    <div>
      <p>資本金</p>
      <p>100万円</p>
    </div>
    <div>
      <p>決算期</p>
      <p>6月</p>
    </div>
    <div>
      <p>事業内容</p>
      <p>データサイエンス及びプログラミングに関する講座の作成・販売・コンサルティング業務</p>
    </div>
    <div>
      <p></p>
      <p>データ分析・データベース作成・ソフトウェアのシステム構築の業務委託</p>
    </div>
    <br/>
    <div>
      <p>代表取締役</p>
      <p>村本耀一</p>
    </div>
    <div>
      <p>代表取締役</p>
      <p>古澤智裕</p>
    </div>
    </div>
  </div>
)
export default Corporate
